import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  users: [],
  payment: [],
  dashboardCounts: {},
};

const homeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {
        dsshbordInit: (state) => {
            state.isLoading = true;
        },
        dashbordSuccessUsers: (state, action) => {
            state.users = action.payload;
        },
        dashBoardData: (state, action) => {
            state.dashboardCounts = action.payload;
        },
        dashbordSuccessPayment: (state, action) => {
            state.payment = action.payload;
            state.isLoading = false;
        },
        dashboardError: (state) => {
            state.isLoading = false;
        },
    },
});
export const {dashBoardData, dsshbordInit, dashbordSuccessUsers, dashbordSuccessPayment, dashboardError } =
    homeSlice.actions;
export default homeSlice.reducer;
