
import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import {
  Row,
  Col, Button,
  Modal
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { logOutInit, logOutSuccess } from "../../store/slice/authSlice";
import Loading from "../Loading";
import { ExclamationCircleOutlined, LeftOutlined } from "@ant-design/icons";
// import logout from "../assets/images/logout.gif";

const profile = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
      fill="#111827"
    ></path>
  </svg>,
];

function Header({
  subName,
}) {

  const { isLoading } = useSelector((state) => state.auth);

  useEffect(() => window.scrollTo(0, 0));
  const disptach = useDispatch()
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const onLogout = () => {
    setIsOpen(false)
    disptach(logOutInit());
    setTimeout(() => {
      disptach(logOutSuccess());
    }, 1000);
  }


  return (
    <>
      {isLoading && <Loading />}
      <Modal
        open={isOpen}
        onOk={onLogout}
        onCancel={() => setIsOpen(false)}
        footer={null}
        maskClosable={false}
        centered
        className="logout-popup"
      >
        <div className="content">
          <ExclamationCircleOutlined className="icon" />
          <h2>Logout</h2>
          <p>Are you sure you want to logout?</p>
          <div className="actions">
            <Button onClick={onLogout} className="logout-btn">
              Yes, Logout
            </Button>
            <Button onClick={() => setIsOpen(false)} className="cancel-btn">
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <Row gutter={[24, 0]}>
        <Col span={24} md={6}>
          <div className="ant-page-header-heading">
            {subName === 'profile' && (
              <Button style={{ marginRight: 16 }}
                onClick={() => { navigate(-1) }} icon={<LeftOutlined />}>
                Back
              </Button>
            )}
            <span
              className="ant-page-header-heading-title"
              style={{ textTransform: "capitalize" }}
            >
              {subName?.replace("/", "")}
            </span>
          </div>
        </Col>
        <Col span={24} md={18} className="header-control">
          <Link className="btn-sign-in" onClick={() => setIsOpen(true)}>
            {profile}
            <span>{"Logout"}</span>

            {/* <span>{"Logout"}</span> */}
          </Link>
          {/* <Link className="btn-sign-in" onClick={() => navigate("/profile")}>
            
            {profile}
            <span>{userInfo?.userName}</span>
          </Link> */}
        </Col>
      </Row>
    </>
  );
}

export default Header;
