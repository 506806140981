import {
  Routes,
  Route
} from "react-router-dom";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import AuthGuard from "./AuthGuard";
import { routesData } from "./Routes";
import React from "react";

function App() {
  const { isLoggedin } = useSelector((state) => state.auth);
	
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        // hideProgressBar={true}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        closeButton={false}
        pauseOnHover
      />
      <Routes>
        <Route index element={<SignIn />} />
          <Route element={<AuthGuard isLoggedIn={isLoggedin} />}>
            {routesData.map(({ element: Element, path, sidebar, header }) =>
              header ? (
                <Route
                  path={`${path}`}
                  key={path}
                  element={
                    <Main>
                      <Element />
                    </Main>
                  }
                />
              ) : (
                <Route path={`${path}`} key={path} element={<Element />} />
              )
            )}
          </Route>
      </Routes>
    </>
  );
}

export default App;
