import { lazy } from "react";

export const routesData = [
  {
    path: "/dashboard",
    element: lazy(() => import("./pages/Home")),
    sidebar: true,
    header: true,
  },
  {
    path: "/users",
    element: lazy(() => import('./pages/Users')),
    sidebar: true,
    header: true,
  },
  {
    path: "/subscriptions",
    element: lazy(() => import("./pages/Billing")),
    sidebar: true,
    header: true,
  },
  {
    path: "/profile",
    element: lazy(() => import("./pages/Profile")),
    sidebar: true,
    header: true,
  },
  {
    path: "/plans",
    element: lazy(() => import("./pages/Settings")),
    sidebar: true,
    header: true,
  },
  {
    path: "/singlechat",
    element: lazy(() => import("./pages/SingleChat")),
    sidebar: true,
    header: true,
  },
  {
    path: "/*",
    element: lazy(() => import("./pages/ErrorPageContainer")),
    sidebar: false,
    header: false,
  },
];
