import {
  combineReducers,
  configureStore
} from "@reduxjs/toolkit";
import { useDispatch } from 'react-redux';
import {
  persistStore
} from 'redux-persist';
import authSlice from './slice/authSlice';
import homeSlice from "./slice/homeSlice";
import subsctibtionSlice from "./slice/subsctibtionSlice";
import userSlice from "./slice/userSlice";
import profileSlice from "./slice/profileSlice";
import packagesSlice from "./slice/packagesSlice";


const rootReducer = combineReducers({
  auth: authSlice,
  homeSlice: homeSlice,
  subs: subsctibtionSlice,
  user: userSlice,
  profileSlice: profileSlice,
  package: packagesSlice ,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});


export const useAppDispatch = () => useDispatch();

export const persistor = persistStore(store);

export const { dispatch: DISPATCH } = store;

export default store;
