import secureLocalStorage from "react-secure-storage";

export const removeToken = (key) => {
  return Promise.resolve().then(() => secureLocalStorage.removeItem(key));
};

export const setToken = (key, value) => {
  return Promise.resolve().then(() => secureLocalStorage.setItem(key, value));
};

export const getToken = (key) => {
  return secureLocalStorage.getItem(key) || "";
};

export const calculateAge = (dob) => {
  if (dob === undefined) {
    return "";
  }
  const currentDate = new Date();
  const birthDate = new Date(dob);
  let age = currentDate.getFullYear() - birthDate.getFullYear();
  if (
    currentDate.getMonth() < birthDate.getMonth() ||
    (currentDate.getMonth() === birthDate.getMonth() &&
      currentDate.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};

export const valiadteToken = () => {
  const token = getToken("login-auth-token");
  const isLoggedIn = getToken("isLoggedIn");
  if (token && isLoggedIn) {
    return true;
  }
  return false;
};
