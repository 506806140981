
import React, { useEffect } from "react";
import {
  Layout, Button,
  Row,
  Col,
  Typography,
  Form,
  Input
} from "antd";
import signinbg from "../assets/images/connectionLogo.png";
import { login } from "../service/api/apiService";
import { useAppDispatch } from "../store";
import { useSelector } from "react-redux";
import Loading from "../components/Loading";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;
const { Content } = Layout;



const SignIn = () => {
  const dispatch = useAppDispatch();
  	const navigate = useNavigate();

  const {isLoading, isLoggedin} = useSelector((state) => state.auth);

  const onFinish = (values) => {
    const userData = { ...values, deviceType : 'I'};
    dispatch(login(userData));
  };

  const onFinishFailed = (errorInfo) => {
    
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
		if (isLoggedin) {
			navigate('/dashboard');
		}
	}, [isLoggedin]);


  return (
    <div className="sign-in-back">
      {isLoading && <Loading />}
      <Layout className="layout-default layout-signin">
        <Content className="signin">
          <Row align={"middle"} gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <div className="signInBorder">
                <Title className="mb-15">Sign In</Title>
                <Title className="font-regular text-muted" level={5}>
                  Enter your email and password to sign in
                </Title>
                <Form
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    className="username"
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                    ]}
                  >
                    <Input placeholder="Email" enterKeyHint="next" />
                  </Form.Item>

                  <Form.Item
                    className="username"
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input placeholder="Password"  type="password" enterKeyHint="done"/>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%", marginTop: 8 }}
                      disabled={isLoading}
                    >
                      <span>SIGN IN</span>
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>

            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img src={signinbg} alt="" />
            </Col>
          </Row>
        </Content>
      </Layout>
    </div>
  );
}

export default SignIn