const commonPath = "/api/v1/admin/";
// Authentication
export const LOGIN_URL = `${commonPath}login`;
export const DASHBOARD_USER = `${commonPath}dashboard/new-user`;
export const DASHBOARD = `${commonPath}dashboard/statistics`;
export const DASHBOARD_PAYMENT = `${commonPath}dashboard/new-payment`;

export const PAYMENTS = `${commonPath}shared/transactions`;
export const USERS_LIST = `${commonPath}shared/users`;
export const PACKAGE_LIST = `${commonPath}shared/packages`;

export const PROFILE_BY_ID = `/api/v1/user`;


