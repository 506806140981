import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  subsctibtion: [],
  total: 0
};

const susbSlice = createSlice({
  name: "subsriptions",
  initialState,
  reducers: {
    subsInit: (state) => {
      state.isLoading = true;
    },
    subsSuccess: (state, action) => {
      state.subsctibtion = action.payload.data;
      state.isLoading = false;
      state.total = action.payload.total;
    },
    subsError: (state) => {
      state.isLoading = false;
    },
  },
});
export const { subsInit, subsSuccess, subsError } = susbSlice.actions;
export default susbSlice.reducer;
