import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  total: 0,
  plans: [],
};

const packageSlice = createSlice({
  name: "packages",
  initialState,
  reducers: {
    packageInit: (state) => {
      state.isLoading = true;
    },
    packageSuccess: (state, action) => {
      console.log('====================================');
      console.log("total", action.payload);
      console.log('====================================');
      state.plans = action.payload.data;
      state.total = action.payload.total;
      state.isLoading = false;
    },
    packageError: (state) => {
      state.isLoading = false;
    },
    clearProfilePage: (state) => {
      return initialState;
    },
  },
});
export const { packageInit, packageSuccess, packageError } = packageSlice.actions;
export default packageSlice.reducer;
