import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  usersList: [],
  total: 0,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    usersInit: (state) => {
      state.isLoading = true;
    },
    usersSuccess: (state, action) => {
      console.log("===> ction.payload", action.payload.data);
      state.usersList = action.payload.data;
      state.total = action.payload.total;
      state.isLoading = false;
    },
    usersError: (state) => {
      state.isLoading = false;
    },
  },
});
export const { usersInit, usersSuccess, usersError } = userSlice.actions;
export default userSlice.reducer;
