import axios from 'axios';
import { toast } from 'react-toastify';
import { DISPATCH } from "../../store";
import { logOutSuccess } from "../../store/slice/authSlice";
import { getToken } from "../../utils/utils";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL 
});

instance.interceptors.request.use((req) => {
    const token = getToken('login-auth-token');
    const auth = token ? `Bearer ${token}` : '';
    req.headers['Authorization'] = auth;
    req.headers['access-control-allow-origin'] = '*';
    req.headers['Content-Type'] = 'application/json; charset=utf-8';
    req.headers['accept-language'] = 'en';
    return req;
});


instance.interceptors.response.use(
    res => {
        try {
            if (res.status === 200 || res.status === 201 || res.status === 204) {
                return res;
            } else if (res.data !== '') {
                toast.error('Somthing went wrong.');
            } else {
                toast.error('Somthing went wrong.');
            }
        } catch (err) {
            toast.error(res.data.data.message);
        }
        return res;
    },
    async error => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            DISPATCH(logOutSuccess());
            return Promise.reject(error);
        }
        return Promise.reject(error);
    }
);

export default instance;
