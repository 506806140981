import { createSlice } from "@reduxjs/toolkit";
// import { removeToken, setToken,valiadteToken } from "../../shared/utils/utils";
import { removeToken, setToken, valiadteToken } from "../../utils/utils";

const initialState = {
  isLoading: false,
  isLoggedin: valiadteToken(),
  isLoggedout: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    validateUser: state => {
    	state.isLoggedin = valiadteToken();
    },
    loginInit: (state) => {
      state.isLoading = true;
    },
    loginSucess: (state, action) => {
      setToken("login-auth-token", action.payload.accessToken).then(() => {
        setToken("isLoggedIn", "true");
      });
      state.isLoading = false;
      state.isLoggedin = true;
      state.isLoggedout = false;
    },
    loginError: (state) => {
      state.isLoading = false;
    },
    logOutInit: (state) => {
      state.isLoading = true;
      state.isLoggedout = false;
    },
    logOutSuccess: (state) => {
      state.isLoading = false;
      state.isLoggedout = true;
      state.isLoggedin = false;
      removeToken("login-auth-token");
      removeToken("isLoggedIn");
      window.location.replace("/");
    },
    logOutError: (state) => {
      state.isLoading = false;
      state.isLoggedout = false;
    },
  },
});
export const {
  loginInit,
  loginSucess,
  loginError,
  logOutInit,
  logOutSuccess,
  logOutError,
  validateUser,
} = authSlice.actions;
export default authSlice.reducer;
