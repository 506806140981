import { toast } from "react-toastify";
import axios from './api/axiosConfig';
import { removeToken } from "../utils/utils";

export const METHOD = {
  POST: "post",
  GET: "get",
  PUT: "put",
  DELETE: "delete",
  PATCH: "patch",
};


export const apiCall = (
  endpoint,
  params = {},
  onSuccess,
  onFailure,
  method,
  DynamicConfig
) => {

  const request = async () => {
    let config = { ...DynamicConfig };
    let axiosRequest;

    switch (method) {
      case METHOD.POST:
        axiosRequest = axios.post(endpoint, params, config);
        break;
      case METHOD.GET:
        axiosRequest = axios.get(endpoint, config);
        break;
      case METHOD.DELETE:
        axiosRequest = axios.delete(endpoint, config);
        break;
      case METHOD.PUT:
        axiosRequest = axios.put(endpoint, params, config);
        break;
      case METHOD.PATCH:
        axiosRequest = axios.patch(endpoint, params, config);
        break;
      default:
        // Handle unsupported methods or default to POST
        axiosRequest = axios.post(endpoint, params, config);
        break;
    }

    try {
      const response = await axiosRequest;
      return response.data;
    } catch (error) {
      // Handle error if necessary
      console.error("Request failed:", error);
      throw error;
    }
  };


  const req = request();

  req
    .then((response) => {
      if (
        (response.status === 200 ||
          response.status === 201 ||
          response.status === 204 ||
          response.data)
      ) {
        if (DynamicConfig.showToast && response.data.message) {
          toast.success(response.data.message);
        }
        onSuccess(response.data);
      } else if (response.status === 200 || response.status === 204) {
        onSuccess("success");
      }else if( response.isError === false) {
        onSuccess("success");
      } else {
        onFailure("Something went wrong");
      }
    })
    .catch((error) => {
      if (error && error.response) {
        if (DynamicConfig.showToast && error.response.data.message) {
          if (error.response.status === 500) {
            toast.error("Server Error");
          } else {
            toast.error(error.response.data.message);
          }
        }

        switch (error.response.status) {
          case 401:
            window.location.replace("/");
            removeToken("login-auth-token");
            removeToken("isLoggedIn");
            onFailure(
              error.response.data && typeof error.response.data.detail
                ? error.response.data.detail
                : "Session expired"
            );

            break;

          case 404:
            onFailure(
              error.response.data && typeof error.response.data.detail
                ? error.response.data.detail
                : "Not Found"
            );
            // window.location?.replace("/pageNotFound");
            break;

          default:
            onFailure(
              error.response.data ? error.response.data : "Something went wrong"
            );
            break;
        }
      } else onFailure && onFailure("Something went wrong");
    });
};
