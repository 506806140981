import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  profile: {},
  
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    profileInit: (state) => {
      state.isLoading = true;
    },
    profileSuccess: (state, action) => {
      state.profile = action.payload;
      state.isLoading = false;
    },
    profileError: (state) => {
      state.isLoading = false;
    },
    clearProfilePage: (state) => {
        return initialState
    }
  },
});
export const { profileInit, profileError, profileSuccess } = profileSlice.actions;
export default profileSlice.reducer;
