import "antd/dist/reset.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { Provider } from "react-redux";
import store from "./store";
import { Suspense } from "react";
import Loading from "./components/Loading";
import AppRoutes from "./AppRoutes";
import { BrowserRouter as Router } from "react-router-dom";
  import "react-toastify/dist/ReactToastify.css";


function App() {
  return (
    <Suspense fallback={<Loading />}>
      <Provider store={store}>
        <Router>
          <AppRoutes />
        </Router>
      </Provider>
    </Suspense>
  );
}

export default App;
